// Dependencies
import helpers from './helpers.js'

// Confirm before destructive changes
helpers.on('submit', '[data-confirm]', function(e) {
	const message = this.dataset.confirm
	if (!confirm(message)) {
		e.preventDefault()
		return false
	}
})

// helpers.on('submit', 'form.js-ajax', function(e) {
// 	checkbox.addEventListener('change', (event) => {
// 		if (event.currentTarget.checked) {
// 		  alert('checked');
// 		} else {
// 		  alert('not checked');
// 		}
// 	  })
// })

helpers.on('change', '[type="checkbox"].js-autosave', function(e) {
	this.closest('form').submit()
})

helpers.on('submit', 'form.js-ajax', function(e) {

	e.preventDefault()

	// Gather info
	const url = this.action
	let data = {}

	// Create data object
	for (const pair of new FormData(this)) {
		data[pair[0]] = pair[1]
	}

	// Send to server
	helpers.ajax.post(url, data).then(response => {
		log({response})
		// tta.notify(response.message, response.status) // Notification
		// tta.updatePage(response.data) // Update site preview, if needed
		// if (response.forward) tta.getSidebarPage(response.forward) // Forward page, if needed
	})

})
