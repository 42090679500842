// General-purpose functions/helpers not unique to 22Slides

// Globals
window.log = console.log

// Shorter alias for querySelector
window.get = document.querySelector.bind(document)
window.getAll = document.querySelectorAll.bind(document)

// Create global timer object
const timers = {}

const helpers = {

	// This only allows for one timer at a time, replaced with function below
	// delay: (() => {
	// 	let timer = 0
	// 	return function(callback, ms) {
	// 		clearTimeout(timer)
	// 		timer = setTimeout(callback, ms)
	// 	}
	// })(),

	delay: (callback, ms, name="default") => {
		clearTimeout(timers[name])
		timers[name] = setTimeout(callback, ms)
	},

	isDOM: el => el instanceof Element,

	on: (events, selectors, handler, doc=document) => {

		if (selectors) {
	
			// Convert events string to array
			events = events.split(' ')
	
			// Loop through events
			for (const event of events) {
	
				// Convert selector string to array
				if (typeof selectors === "string") selectors = selectors.split(',')
	
				// Loop through selectors
				for (const selector of selectors) {
	
					doc.addEventListener(event, e => {
						var target = e.target
						while (target != null) {
							if (target.matches && target.matches(selector)) {
								handler.call(target, e)
								return
							}
							target = target.parentElement
						}
					}, true)
	
				}
	
			}
	
		} else {
			// No selector, single action
			doc.addEventListener(events, e => {
				handler.call(e.target, e)
			}, true)
		}
	
	},

	getValue: el => {
		let value
		if (el instanceof jQuery) el = el[0] // Convert jQuery objects to vanilla js
		if (el.matches('[type="checkbox"]')) {
			value = el.checked ? true : false
		} else {
			value = el.value
		}
		return value
	},

	escapeQuotes: content => content.replace(/[""]/g, '\''),

	random: (min=0, max=1000) => Math.floor(Math.random() * (max - min + 1)) + min,

	urnify: input => {
		var urn = input.toLowerCase()
		var urn = urn.replace('&', 'and')
		var urn = urn.replace('/', '-')
		var urn = urn.replace(' ', '-')
		var urn = urn.replace(/\-+/g, '-')
		var urn = urn.replace(/^\-+/g, '')
		// var urn = urn.replace(/\-+$/g, '') // this prevents the adding of new spaces/dashes
		var urn = urn.replace(/[^a-z0-9\-]/g, '')
		return urn
	},

	subdomainify: input => {
		var urn = input.toLowerCase()
		var urn = urn.replace('&', 'and')
		var urn = urn.replace('/', '')
		var urn = urn.replace(' ', '')
		var urn = urn.replace(/\-+/g, '')
		var urn = urn.replace(/^\-+/g, '')
		// var urn = urn.replace(/\-+$/g, '') // this prevents the adding of new spaces/dashes
		var urn = urn.replace(/[^a-z0-9\-]/g, '')
		return urn
	},

	boolean: value => {
		if (value === "false") value = false
		if (value === "0") value = false
		if (value === 0) value = false
		if (value === "true") value = true
		if (value === "1") value = true
		if (value === 1) value = true
		return value
	},

	formToJSON: form => {
		const json = {}
		const formData = new FormData(form)
		for (const key of formData.keys()) {
			json[key] = formData.get(key)
		}
		return json 
	},

	stringToBool: string => string === 'false' ? false : !!string,

	triggerEvent: (event, selector) => {
		const elements = document.querySelectorAll(selector)
		for (const element of elements) {
			element.dispatchEvent(new Event(event))
		}
	},

	triggerEvent2: (event, element) => {
		const e = new Event(event, {
			bubbles: true,
			cancelable: true,
		})
		element.dispatchEvent(e)
	},

	stretchyField: function() {
		this.parentNode.dataset.value = this.value
	},

	autosave: () => {

		helpers.on('input', '[data-autosave]', function(e) {

			// Save elements
			const input = this
			const form = input.closest('form')
			const feedback = input.closest('div').querySelector('.js-feedback')

			// Reset error messages
			feedback.textContent = ""
			feedback.classList.remove('success')
			feedback.classList.remove('error')

			delay(async () => {

				// Get variables
				const action = input.dataset.autosave
				const method = "post"
				const body = {}
				body[input.name] = input.value

				// Send request
				const response = await fetch(action, {
					method: method,
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(body),
				})

				// log('response', response)

				// Parse response
				data = await response.json()

				// Show error message
				feedback.textContent = data.message
				feedback.classList.add(data.status)

				log('data', data)

				// Handle errors
				// if (data.errors) {
				// 	log('errors', data.errors)
				// 	for (errorName of Object.keys(data.errors)) {
				// 		this.querySelector(`.error.${errorName}`).textContent = data.errors[errorName]
				// 	}
				// }

				return data

			}, 500)

		})

	},

	back: () => {
		helpers.on('click', '.js-back', e => {
			e.preventDefault()
			history.back()
		})
	},

	toElement: string => {
		const container = document.createElement('div')
		container.innerHTML = string.trim()
		const element = container.firstChild
		return element
	},

	unwrap: el => {
		let parent = el.parentNode
		while (el.firstChild) parent.insertBefore(el.firstChild, el)
		parent.removeChild(el)
	},

	wrap: (el, wrapperString) => {
		let wrapper = tt.toElement(wrapperString)
		el.parentNode.insertBefore(wrapper, el)
		wrapper.appendChild(el)
		return wrapper
	},

	insertAfter: (elementString, reference) => {
		let el = tt.toElement(elementString)
		reference.parentNode.insertBefore(el, reference.nextSibling)
	},

	isJSON: data => {
		log({data})
		// const test = JSON.parse(data)
		if (typeof data === "object") {
			return true
		} else {
			return false
		}
	},

	prevAll: element => {
		const result = []
		while (element = element.previousElementSibling) {
			result.push(element)
		}
		return result
	},

	ajax: {
		post: async (url, data={}) => {

			// Convert from formdata to normal object
			data = helpers.formDataToObject(data)
		
			const options = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			}

			try {
				const response = await fetch(url, options)
				const json = await response.json()
				return json
			} catch (err) {
				return err
			}

		},
		get: async (url, data={}) => {

			url = url + "?" + new URLSearchParams(data)

			const response = await fetch(url)
			const text = await response.text()

			try {
				const data = JSON.parse(text)
				return data
				// return {
				// 	data,
				// 	json: true,
				// }
			} catch (err) {
				return text
			}

		},
	},

	formDataToObject: data => {
		if (data instanceof FormData) {
			const object = {}
			data.forEach((value, key) => object[key] = value)
			data = object
		}
		return data
	},

	cleanArray: array => {
		var filtered = array.filter((el) => {
			// return el != null
			return el != ""
		})
		return filtered
	},

	escapeRegExp: string => string.replace(/[.*+?^${}()\-|[\]\\]/g, '\\$&'),

	escapeHTML: html => {
		// Escape HTML
		html = document.createElement('div')
			.appendChild(document.createTextNode(html))
			.parentNode
			.innerHTML
		// Escape quotes
		html = html.replace(/[""]/g, '\\"')
		return html
	},

	hasValue: (object, value) => {
		return Object.values(object).some(function(val) {
			if (val === value) {
				return true
			}
			if (val && typeof val === 'object') {
				return helpers.hasValue(val, value)
			}
			if (val && Array.isArray(val)) {
				return val.some((obj) => {
					return helpers.hasValue(obj, value)
				})
			}
		})
	},

	isEmpty: object => Object.keys(object).length === 0,

	contrastColor: hex => {

		// Remove hash
		if (hex.indexOf('#') === 0) hex = hex.slice(1)

		// convert 3-digit hex to 6-digits
		if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]

		// Validate
		if (hex.length !== 6) throw new Error('Invalid HEX color')

		const r = parseInt(hex.slice(0, 2), 16)
		const g = parseInt(hex.slice(2, 4), 16)
		const b = parseInt(hex.slice(4, 6), 16)

		return ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ? '#000000' : '#ffffff'

	},

}

/* --------------- EXPORT --------------- */

export default helpers
// tt.helpers = helpers
window.helpers = helpers